<template>
  <section
    class="visibility-auto group relative overflow-hidden rounded-lg"
    :class="{
      '-sm:overflow-visible -sm:rounded-none': mobileLayout,
    }"
    data-cy-course-card
    :data-cy-course-card-id="course.id"
    :style="{
      width: width ? `${width}px` : undefined,
      '--borderColor': mainTagColors?.border,
      '--backgroundColor': mainTagColors?.background,
    }"
  >
    <nuxt-link
      class="relative block aspect-[3/4] w-full"
      :class="{
        '-sm:grid -sm:aspect-auto -sm:grid-cols-[110px_1fr] -sm:items-center -sm:gap-3 -xs:grid-cols-[80px_1fr]':
          mobileLayout,
      }"
      :to="course._links.coursePage"
    >
      <div
        class="absolute h-full w-full overflow-hidden rounded-lg"
        :class="{
          '-sm:static -sm:aspect-square': mobileLayout,
        }"
      >
        <app-image
          :alt="course.teacher.full_name!"
          class="h-full w-full object-cover transition duration-500 group-hover:scale-105"
          :class="{
            '-sm:group-hover:scale-100': mobileLayout,
          }"
          :src="course.teacher.avatar_url"
          :width="600"
        />
      </div>

      <course-card-content
        class="h-full w-full bg-gradient-to-t from-black/70 from-20% to-70% p-4 text-white transition group-hover:translate-y-1 group-hover:opacity-0"
        :class="{
          '-sm:from-transparent -sm:p-0 -sm:text-black -sm:group-hover:translate-y-0 -sm:group-hover:opacity-100':
            mobileLayout,
        }"
        :course="course"
        :mobile-layout="mobileLayout"
        :show-next-session="showNextSession"
      />
    </nuxt-link>

    <div
      class="absolute inset-0 rounded-lg bg-[--backgroundColor] opacity-0 mix-blend-screen transition group-hover:opacity-80"
      :class="{ 'hidden sm:block': mobileLayout }"
    />

    <div
      class="absolute inset-0 overflow-hidden rounded-lg border-2 border-[--borderColor] opacity-0 transition-all duration-300 group-hover:opacity-100 group-hover:backdrop-blur-lg"
      :class="{ 'hidden sm:block': mobileLayout }"
    >
      <course-card-overlay
        class="h-full"
        :course="course"
        :main-tag="mainTag"
        :main-tag-colors="mainTagColors"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";

const properties = defineProps<{
  course: CourseListView;
  showNextSession?: boolean;
  mobileLayout?: boolean;
}>();

const width = computed(
  () =>
    inject<CarouselProvidedState | null>(CarouselProvideKey, null)?.itemWidth,
);

const mainTag = computed(() => properties.course.tags[0]);

const mainTagColors = computed(() => {
  if (!mainTag.value) return;

  return {
    border: hexToHSL(mainTag.value.color, {
      luminance: 80,
      saturation: 50,
    }),
    background: hexToHSL(mainTag.value.color, {
      luminance: 85,
      saturation: 70,
    }),
    text: hexToHSL(mainTag.value.color, {
      luminance: 15,
      saturation: 70,
    }),
  };
});
</script>
