<template>
  <div ref="wrapper">
    <app-card-link-background
      class="visibility-auto !rounded-t"
      :to="course._links.coursePage"
    >
      <app-card
        class="group relative grid h-full gap-4 !bg-transparent"
        :class="{
          'grid-cols-[80px_1fr] grid-rows-1 items-stretch sm:!grid-cols-1 sm:grid-rows-[auto_1fr] xs:grid-cols-[100px_1fr]':
            mobileRowLayout,
          'max-w-[400px] grid-rows-[auto_1fr]': !mobileRowLayout,
        }"
        data-cy-course-card
        :data-cy-course-card-id="course.id"
        :style="{
          width: width ? `${width}px` : undefined,
        }"
        tag="section"
      >
        <teacher-card-wrapped
          class="md:!aspect-[3/4]"
          :class="{ 'h-full sm:h-auto': mobileRowLayout }"
          :hovered="isHovered"
          :teacher="course.teacher"
          :video="course.video_url"
        />

        <div class="grid place-content-start sm:gap-4">
          <div
            class="flex flex-wrap gap-2 text-black"
            :class="{ 'order-3 mt-2 sm:order-1 sm:mt-0': mobileRowLayout }"
          >
            <course-rating-chip
              v-if="course.ratings"
              size="small"
              :total-number-of-ratings="course.ratings.count"
              :value="course.ratings.avg"
            />
            <course-fresh-chip v-else size="small" />

            <course-session-date-chip
              v-if="nextSession"
              :session="nextSession"
              size="small"
            />
          </div>

          <h3
            class="line-clamp-2 font-semibold leading-normal"
            :class="{ 'order-1 sm:order-2': mobileRowLayout }"
          >
            {{ course.title }}
          </h3>

          <p
            v-if="mobileRowLayout"
            class="order-2 text-sm text-subtle sm:hidden"
          >
            {{ course.teacher.full_name }}<br />
            <teacher-companie-logos
              :companies="course.teacher.companies"
              :height="25"
            />
          </p>
        </div>

        <div v-if="nextSession" class="absolute left-2 top-2 hidden sm:block">
          <course-session-sold-out-chip
            v-if="nextSessionIsSoldOut"
            :has-other-sessions="hasOtherNonSoldOutSessions"
          />
          <course-session-relative-start-date-chip
            v-else
            :session="nextSession"
            size="small"
          />
        </div>
      </app-card>
    </app-card-link-background>
  </div>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";

const properties = defineProps<{
  course: CourseListView;
  mobileRowLayout?: boolean;
}>();

const nextSession = computed(() => properties.course.sessions[0]);
const wrapperElement = templateRef<HTMLElement>("wrapper");
const isHovered = useElementHover(wrapperElement);

const width = computed(
  () =>
    inject<CarouselProvidedState | null>(CarouselProvideKey, null)?.itemWidth,
);

const nextSessionIsSoldOut = computed(
  () =>
    nextSession.value &&
    (nextSession.value.is_sold_out || !nextSession.value.public),
);
const hasOtherNonSoldOutSessions = computed(() =>
  properties.course.sessions.some(
    (session) => !session.is_sold_out && session.public,
  ),
);
</script>
