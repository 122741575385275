<template>
  <app-page-section :title="displayedTitle">
    <course-card-carousel :id="id" :list="list" />

    <template #append>
      <slot name="append">
        <search-results-button
          color="dark"
          :origin="id"
          :search-filters="searchFilters"
          size="lg"
          variant="outlined"
        />
      </slot>
    </template>
  </app-page-section>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";
import type { I18nMessage } from "~/core/i18n/i18n.model";
import type { TrackingPlanModel } from "~~/model/analytics.model";

const { t } = useI18n();

const properties = withDefaults(
  defineProps<{
    title?: I18nMessage;
    list: CourseListView[];
    id: TrackingPlanModel["course_clicked"]["origin"];
    searchFilters?: {
      tags?: string[];
    };
  }>(),
  {
    title: undefined,
    searchFilters: undefined,
  },
);

const displayedTitle = computed(
  () => properties.title ?? t("course.card_list.default_title"),
);
</script>
