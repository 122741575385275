<template>
  <app-carousel
    :item-gap="0"
    :item-width="courseCardV2Enabled ? 290 : 250"
    :items="list"
  >
    <template #item="{ item }">
      <div class="h-full p-2">
        <course-card
          v-if="courseCardV2Enabled"
          :course="item"
          :show-next-session="showNextSession"
          @click="onCardClick(item)"
        />
        <course-card-old
          v-else
          class="h-full"
          :course="item"
          @click="onCardClick(item)"
        />
      </div>
    </template>
  </app-carousel>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";
import {
  toAnalyticsCourseView,
  type TrackingPlanModel,
} from "~~/model/analytics.model";

const properties = defineProps<{
  list: CourseListView[];
  id: TrackingPlanModel["course_clicked"]["origin"];
  showNextSession?: boolean;
}>();

const { $analytics } = useNuxtApp();

const onCardClick = (course: CourseListView) =>
  $analytics.track("course_clicked", {
    origin: properties.id,
    course: toAnalyticsCourseView(course),
  });

const courseCardV2Enabled = useFeatureFlag("course_card_v2");
</script>
