<template>
  <div
    class="relative grid grid-rows-[auto_1fr_auto] justify-between gap-3"
    :class="{
      '-sm:flex -sm:flex-col -sm:justify-start -sm:gap-1': mobileLayout,
    }"
  >
    <div
      class="flex flex-wrap gap-1"
      :class="{ '-sm:order-3 -sm:mt-1': mobileLayout }"
    >
      <course-rating-chip
        v-if="course.ratings"
        class="!bg-white !text-black"
        compact
        size="small"
        :total-number-of-ratings="course.ratings.count"
        :value="course.ratings.avg"
      />
      <template v-if="showNextSession">
        <course-session-sold-out-chip
          v-if="nextSessionIsSoldOut"
          :class="{ '-sm:border': mobileLayout }"
          color="light"
          :has-other-sessions="hasOtherNonSoldOutSessions"
        />
        <course-session-date-chip
          v-else-if="nextSession"
          class="bg-white text-black"
          :class="{ '-sm:border': mobileLayout }"
          :session="nextSession"
          size="small"
        />
      </template>
    </div>

    <div class="content-end">
      <p
        class="line-clamp-2 font-title text-xl"
        :class="{
          '-sm:order-1 -sm:font-sans -sm:text-lg/snug -sm:!font-semibold':
            mobileLayout,
        }"
      >
        {{ course.subject || course.title }}
      </p>
    </div>

    <p
      class="w-full overflow-hidden"
      :class="{
        '-sm:order-2 -sm:grid -sm:items-center -sm:gap-y-1': mobileLayout,
      }"
    >
      <span
        class="block text-sm font-semibold"
        :class="{ '-sm:font-normal -sm:text-subtle': mobileLayout }"
      >
        {{ course.teacher.full_name }}
      </span>

      <span
        class="block overflow-hidden text-ellipsis whitespace-nowrap text-sm"
        :class="{ '-sm:hidden': mobileLayout }"
      >
        {{ course.teacher.job }}
      </span>

      <teacher-companie-logos
        v-if="course.teacher.companies.length > 0"
        class="mt-2"
        :class="{ '-sm:mt-0': mobileLayout }"
        :companies="course.teacher.companies"
        :height="24"
        :max-width="`${100 / course.teacher.companies.length - 5}%`"
      />
    </p>
  </div>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";

const properties = defineProps<{
  course: CourseListView;
  showNextSession?: boolean;
  mobileLayout?: boolean;
}>();

const nextSession = computed(() => properties.course.sessions[0]);
const nextSessionIsSoldOut = computed(
  () =>
    nextSession.value &&
    (nextSession.value.is_sold_out || !nextSession.value.public),
);
const hasOtherNonSoldOutSessions = computed(() =>
  properties.course.sessions.some(
    (session) => !session.is_sold_out && session.public,
  ),
);
</script>
